.navtab_action {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    border-bottom  : 1px solid var(--primary-color);
    padding-bottom : .8rem;
    margin-bottom  : 2rem;
}

.nav_tab {
    display: flex;
}

.nav_tab button,
.nav_tab button span {
    transition: all .3s ease-in-out;
}

.nav_tab button {
    min-width    : 20rem;
    border-radius: .4rem;
    padding      : .8rem 1.4rem;
}

.nav_tab button.active {
    background-color: rgba(0, 0, 0, 0.4);
}

.nav_tab button span {
    font-weight   : 400;
    font-size     : 2.2rem;
    line-height   : 2.8rem;
    text-transform: capitalize;
    color         : var(--gray-1);
}

.nav_tab button.active span {
    color: var(--primary-color);
}

.nav_content:not(.active) {
    display: none;
}

.btn_snmd_open {
    display         : flex;
    align-items     : center;
    background-color: rgba(0, 0, 0, 0.4);
    color           : var(--primary-color);
    border-radius   : .8rem;
    border          : 1px solid var(--primary-color);
    padding         : .8rem 1.4rem;
}

.btn_snmd_open:hover {
    border-color: var(--gray-1);
}

.btn_snmd_open svg {
    display: inline-block;
    width  : 2.4rem;
    height : 2.4rem;
}

.btn_snmd_open span {
    font-weight: 500;
    font-size  : 2rem;
    line-height: 2.8rem;
    color      : var(--gray-1);
    margin-left: .8rem;
}